import React, { useState } from 'react';
import Select from 'react-select';
import PageTitle from '../layouts/PageTitle';
import { useStaticCollections } from '../../context/StaticCollections';
import { useLoggedInUserContextProvider } from '../../context/LoggedInUserContext';

import { getUserRoles } from '../../utils/getUserRoles';
import Span from './Forms/Elements/Span';

const Masquerade = () => {
  const { userDetails, setUserDetails } = useLoggedInUserContextProvider();
  const { usersArray } = useStaticCollections();

  const [selectedUser, setSelectedUser] = useState(null);
  const [notification, setNotification] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const selectOptions = usersArray.map((user) => ({
    value: user.id,
    label: user.username,
  }));

  const handleChange = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  const masqueradeAsUser = async (selectedUserId) => {
    if (!selectedUserId) {
      return;
    }

    const masqueradeUser = usersArray.find(
      (user) => user.id === selectedUserId,
    );

    if (typeof masqueradeUser === 'object') {
      const userRoles = await getUserRoles(masqueradeUser);
      const hasAdminOrSuperAdmin = userRoles.some(
        (role) => role === 'admin' || role === 'super-admin',
      );

      masqueradeUser.email = userDetails.email;

      if (!hasAdminOrSuperAdmin) {
        setUserDetails({
          ...masqueradeUser,
          roles: userRoles,
        });

        setNotification('Updated');
      } else {
        setErrorMessage('Masquerading as an admin is not allowed');
      }
    }
  };

  return (
    <>
      <PageTitle
        activeMenu="Masquerade"
        motherMenu="Home"
        notificationMessage={notification}
      />
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Masquerade</h4>
        </div>
        <div className="card-body text-left  col-6">
          <label htmlFor="user-select">Masquerade as user</label>
          <Select
            id="user-select"
            value={selectedUser}
            onChange={handleChange}
            options={selectOptions}
          />
          <div className="mt-xl-2">
            <Span name="user-select" text={errorMessage} />
          </div>

          <div className="text-right mt-3">
            <button
              type="button"
              className="btn btn-success"
              onClick={() => masqueradeAsUser(selectedUser.value)}
            >
              Masquerade
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Masquerade;
