import React, { useContext, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { axiosPut } from '../../../../utils/axiosPut';
import ValidationReferee from '../Validation/ValidationReferee';
import WizardWrapper from './WizardWrapper';
import { axiosGet } from '../../../../utils/axiosGet';
import { CommonCollections } from '../../../../App';
import { useStaticCollections } from '../../../../context/StaticCollections';
import { useLoggedInUserContextProvider } from '../../../../context/LoggedInUserContext';
import { axiosPost } from '../../../../utils/axiosPost';
import { useRefereeCollections } from '../../../../context/RefereeReportCollections';

const WizardRefereeReport = () => {
  const { userDetails } = useLoggedInUserContextProvider();
  const { fellows, nonAasReferees, usersArray } = useStaticCollections();
  const {
    setRefereeReportId,
    refreshRefereeReports,
    setRefreshRefereeReports,
  } = useContext(CommonCollections);

  const {
    nonAdminRefereeReportId,
    setNonAdminRefereeReportId,
    refreshNonAdminRefereeReport,
    setRefreshNonAdminRefereeReport,
  } = useRefereeCollections();

  const { refereeReportId } = useContext(CommonCollections);
  const [formState, setFormState] = useState({});
  const [formStatus, setFormStatus] = useState(1);

  const {
    register,
    setValue,
    watch,
    control,
    resetField,
    setError,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidationReferee),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (refereeReportId && userDetails) {
          const response = await axiosGet(
            `referee-reports/${refereeReportId}?filters[refereeUserId][$eq]=${userDetails.id}&populate=*`,
            { validateStatus: false },
          );
          const nominationResponse = await axiosGet(
            `nominations/${response.data.data.attributes.nominationId}`,
          );
          const candidateResponse = await axiosGet(
            `candidates/${nominationResponse.data.data.attributes.candidateId}`,
          );
          const nominationStates = await axiosGet(
            `nomination-states?filters[nominationId][$eq]=${nominationResponse.data.data.id}&populate=*`,
          );
          const electionTypes = await axiosGet(
            `election-Types/${nominationResponse.data.data.attributes.electionTypeId}`,
          );
          const refereeStatus = await axiosGet(
            `referee-statuses/${response.data.data.attributes.refereeStatusId}`,
          );
          response.data.data.nomination = nominationResponse.data.data;
          setFormState(response.data.data);

          setValue(
            'electionTypeId',
            nominationResponse.data.data.attributes.electionTypeId,
          );
          const refereeData = {
            refereeReport: response.data.data,
            candidate: candidateResponse.data.data,
            nominationState: nominationStates.data.data,
            electionType: electionTypes.data.data,
            refereeStatus: refereeStatus.data.data,
          };

          sessionStorage.setItem('refereeReport', JSON.stringify(refereeData));
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    setFormStatus(1);
    fetchData();
    trigger();
  }, [
    refereeReportId,
    userDetails,
    setValue,
    trigger,
    refreshRefereeReports,
    setRefreshRefereeReports,
    nonAdminRefereeReportId,
    setNonAdminRefereeReportId,
  ]);

  const onSubmit = (data) => {
    const { refereeReport, candidate } = JSON.parse(
      sessionStorage.getItem('refereeReport'),
    );
    data.refereeUser = refereeReport.attributes.refereeFellowId
      ? fellows[refereeReport.attributes.refereeFellowId]
      : nonAasReferees[refereeReport.attributes.nonAasRefereeId];
    data.referee = usersArray.find(
      (user) => user.id === refereeReport.attributes.refereeUserId,
    );
    data.candidate = `${candidate.attributes.name} ${candidate.attributes.lastName}`;
    data.refereeStatusId = data.refereeActions;
    data.decline = false;
    data.useExistingReport = false;
    data.requestExtension = false;
    data.testEmail = userDetails.email;
    switch (data.refereeActions) {
      case '2': // Submitted
        data.report.id = data?.reportFileId?.id;
        data.report.name = data?.reportFileName;
        break;

      case '3': // Declined
        data.refereeDeclineSubmittedYear = new Date();
        data.decline = true;
        break;

      case '4': // Use existing report
        data.useExistingReport = true;
        break;

      case '5': // Referee Extension
        data.requestExtension = true;
        break;

      default:
    }
    const refereeData = {
      data: {
        ...data,
        refereeReportId,
      },
    };
    if (data.refereeActions === '5') {
      axiosPost(`referee-report-request-extension`, {
        refereeData,
      }).then((response) => {
        if (response.status === 200) {
          setRefereeReportId(refereeReportId);
          setNonAdminRefereeReportId(nonAdminRefereeReportId);
          setRefreshNonAdminRefereeReport(!refreshNonAdminRefereeReport);
          setRefreshRefereeReports(!refreshRefereeReports);
        }
      });
    } else {
      axiosPut(`update-referee-report/${refereeReportId}/`, {
        refereeData,
      }).then((response) => {
        if (response?.status === 200) {
          setRefereeReportId(refereeReportId);
          setNonAdminRefereeReportId(nonAdminRefereeReportId);
          setRefreshNonAdminRefereeReport(!refreshNonAdminRefereeReport);
          setRefreshRefereeReports(!refreshRefereeReports);
        }
      });
    }
  };

  return (
    <WizardWrapper
      activeMenu="Referee Report"
      motherMenu="Home"
      formName="referees"
      formState={formState}
      setFormState={setFormState}
      setValue={setValue}
      register={register}
      errors={errors}
      control={control}
      resetField={resetField}
      setError={setError}
      watch={watch}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      formStatusId={formStatus}
      trigger={trigger}
    />
  );
};

export default WizardRefereeReport;
