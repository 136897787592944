import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Label from '../Elements/Label';
import Select from '../Elements/Select';
import Span from '../Elements/Span';

import { Context } from '../Wizard/WizardWrapper';
import InputFile from '../Elements/InputFile';
import InputText from '../Elements/InputText';
import { strapiUrl } from '../../../../utils/constants';
import { useLoggedInUserContextProvider } from '../../../../context/LoggedInUserContext';
import { axiosGet } from '../../../../utils/axiosGet';
import DownloadFile from '../Elements/DownloadFile';

const RefereeActions = ({ item, className }) => {
  const { errors, resetField, formState, trigger, watch } = useContext(Context);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [declineReasonValue, setDeclineReasonValue] = useState('');
  const [edit, setEdit] = useState(false);
  const refereeActions = watch('refereeActions');
  const { userDetails } = useLoggedInUserContextProvider();
  const isAdmin =
    userDetails.roles.includes('admin') ||
    userDetails.roles.includes('super-admin');
  const { nomination } = formState || {};
  const canEditNomination =
    nomination?.attributes?.nominationStatusTypeId !== 4 &&
    nomination?.attributes?.nominationStatusTypeId !== 5 &&
    nomination?.attributes?.nominationStatusTypeId !== 6;
  const canEdit = isAdmin && canEditNomination;
  useEffect(() => {
    const fetchData = async () => {
      const { refereeStatusId, declineReasonId } = formState.attributes || {};
      const shouldDisableInput = refereeStatusId !== 1 && refereeStatusId !== 6;
      setInputDisabled(shouldDisableInput);

      if (refereeStatusId === 3 && declineReasonId) {
        try {
          const { data } = await axiosGet(
            `referee-report-decline-reasons/${declineReasonId}`,
          );
          setDeclineReasonValue(data.data.attributes.name);
        } catch (error) {
          console.error(
            'An error occurred while fetching decline reason:',
            error,
          );
        }
      }
    };

    fetchData();
  }, [formState]);

  useEffect(() => {
    trigger();
  }, [refereeActions, trigger]);

  const handleEditToggle = () => setEdit((prevEdit) => !prevEdit);

  const setResetValues = (
    currentOptions,
    eventTargetName,
    eventTargetValue,
  ) => {
    const selectedOption = currentOptions.find(
      (option) => option.id === eventTargetValue,
    );

    if (eventTargetName === 'refereeActions') {
      formState.refereeActions = selectedOption.id;
      [
        'extensionReason',
        'report',
        'reportFileId',
        'reportFileName',
        'reportFilePath',
        'declineReasonId',
        'declineReasonOther',
      ].forEach(resetField);
    }

    if (eventTargetName === 'declineReasonId') {
      formState.declineReasonId = selectedOption.id;
    }

    trigger();
  };

  const renderFileUploadSection = () => {
    if (formState.refereeActions !== 2) return null;

    return (
      <div>
        {(inputDisabled === false || edit === true) && (
          <InputFile
            name="report"
            fileExtension={[{ id: 1, attributes: { name: 'pdf' } }]}
          />
        )}
        {errors.report && <Span text={errors.report.message} />}
      </div>
    );
  };

  const renderExtensionReasonSection = () => {
    if (formState.refereeActions !== 5) return null;

    return (
      <div className="col-lg-6 mt-4">
        <b>
          <Label
            label="Please provide a reason and indicate when you will be able to submit the report"
            name="extensionReasonLabel"
            required={item.required}
          />
        </b>
        <InputText name="extensionReason" />
        {errors.extensionReason && (
          <Span text={errors.extensionReason.message} />
        )}
      </div>
    );
  };

  const renderDeclineReasonSection = () => {
    if (
      (inputDisabled === false || edit === true) &&
      formState.refereeActions === 3
    ) {
      return (
        <div className="col-lg-6 mt-4">
          <b>
            <Label label="Decline Reason" name="declineReasonId" />
          </b>
          <br />
          <Select
            name="declineReasonId"
            options="referee-report-decline-reasons"
            setState={setResetValues}
          />
          {formState.declineReasonId === 5 && (
            <>
              <b>
                <Label
                  label="Please enter your decline reason"
                  name="declineReasonOther"
                />
              </b>
              <InputText name="declineReasonOther" />
              {errors.declineReasonOther && (
                <Span text={errors.declineReasonOther.message} />
              )}
            </>
          )}
          {errors.declineReasonId && (
            <Span text={errors.declineReasonId.message} />
          )}
        </div>
      );
    }

    return null;
  };

  const renderUploadedFileInfo = () => {
    if (formState.attributes?.report?.data) {
      return (
        <div className="form-group mt-2">
          <b>Last Uploaded File:</b>
          <br />
          <DownloadFile
            text={formState.attributes.report.data.attributes.name}
            url={`${strapiUrl}${formState.attributes.report.data.attributes.url}`}
          />
        </div>
      );
    }
    return null;
  };

  const renderDeclineInfo = () => {
    const { refereeStatusId, declineReasonOther, refereeDeclineSubmittedYear } =
      formState.attributes || {};
    if (inputDisabled === true && refereeStatusId === 3) {
      return (
        <>
          <b>
            <Label label="Decline Reason : " name="declineReasonId" />
          </b>
          <p>{declineReasonOther || <b>{declineReasonValue}</b>}</p>
          <b>
            <Label label="Declined On : " name="declineReasonId" />
          </b>
          <p>
            <b>{refereeDeclineSubmittedYear}</b>
          </p>
        </>
      );
    }
    return null;
  };

  const renderExtensionInfo = () => {
    const { refereeStatusId, extensionReason } = formState.attributes || {};
    if (inputDisabled === true && refereeStatusId === 5) {
      return (
        <>
          <b>
            <Label
              label="Referee Report Extension Reason : "
              name="refereeExtensionId"
            />
          </b>
          <p>{extensionReason}</p>
        </>
      );
    }
    return null;
  };

  const renderApprovalNoteInfo = () => {
    const { refereeStatusId, extensionReasonApprovalNote } =
      formState.attributes || {};
    if (inputDisabled === true && refereeStatusId === 6) {
      return (
        <>
          <b>
            <Label
              label="Referee Report Approval Note : "
              name="refereeExtensionApprovalNoteId"
            />
          </b>
          <p>{extensionReasonApprovalNote}</p>
        </>
      );
    }
    return null;
  };

  return (
    <React.Fragment key={`referee-field-collection-${item.name}`}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {inputDisabled && canEdit && (
          <div className="p-2">
            <button
              onClick={handleEditToggle}
              type="button"
              className={`btn btn-${edit ? 'danger' : 'success'}`}
            >
              <span>
                <i className={`fa fa-${edit ? 'x' : 'pencil'}`} />{' '}
                {edit ? 'Cancel' : 'Edit'}
              </span>
            </button>
          </div>
        )}
      </div>
      <div className={className}>
        {renderUploadedFileInfo()}
        {renderDeclineInfo()}
        {renderExtensionInfo()}
        {renderApprovalNoteInfo()}
        <div className="form-group mt-4">
          {(inputDisabled === false || edit === true) && (
            <>
              <b>
                <Label
                  label="Submit, decline, use existing report or request extension below"
                  name="refereeActions"
                  required={item.required}
                />
              </b>
              <Select
                options="referee-actions"
                name="refereeActions"
                setState={setResetValues}
              />
              {errors.refereeActions && (
                <Span text={errors.refereeActions.message} />
              )}
            </>
          )}
        </div>
      </div>
      <div className="form-group">
        {renderFileUploadSection()}
        {renderExtensionReasonSection()}
        {renderDeclineReasonSection()}
      </div>
      <div className="mb-xl-5" />
    </React.Fragment>
  );
};

RefereeActions.propTypes = {
  item: PropTypes.object,
  className: PropTypes.string,
};

export default RefereeActions;
